import { defineComponent, h, ref } from 'vue';
import { IonItemGroup, IonItem, IonLabel, IonButton, IonText, IonIcon, IonInput, IonItemDivider } from '@ionic/vue';
import { handleGeoInput } from './Record';
import { buildOutline } from 'ionicons/icons';
function formatAddress(geoJSON) {
    const props = geoJSON.properties;
    switch (props.type) {
        case 'house':
            return `${props.street} ${props.housenumber}${props.district ? ', ' + props.district : ''}, ${props.city}, ${props.country}`;
        case 'street':
            return `${props.name}, ${props.city}, ${props.country}`;
        case 'district':
            return `${props.name}, ${props.city}, ${props.country}`;
        case 'locality':
            return `${props.name}${props.city ? ', ' + props.city : ''}, ${props.country}`;
        case 'city':
            return `${props.name}, ${props.country}`;
            break;
        case 'county':
            return `${props.name}, ${props.country}`;
        case 'country':
            return `${props.country}`;
        default:
            return `An Error happend`;
    }
}
export default defineComponent({
    props: {
        label: String,
        info: String,
        identifier: String,
        latLng: {
            type: Object,
        },
        recordRef: {
            type: Object,
        }
    },
    setup(props) {
        const currentLatLng = ref(props.latLng);
        const currentInfo = ref(props.info);
        const detailView = ref(false);
        const geoFeatureListRef = ref([]);
        let alreadyPerformedRequest = false;
        return () => {
            // In the detailview the use should be able to modify the 
            // lat/lng/info independend from the geocoding service
            if (detailView.value) {
                return h(IonItem, () => [
                    h(IonLabel, { position: 'stacked' }, () => props.label),
                    h(IonInput, {
                        disabled: true
                    }, () => ['Not implemented yet']),
                    // toggle autocomplete view
                    h(IonButton, {
                        slot: 'end',
                        fill: 'clear',
                        onClick: () => {
                            detailView.value = false;
                        }
                    }, () => [
                        h(IonIcon, { icon: buildOutline })
                    ])
                ]);
            }
            else {
                // Autocomplete User Geoinput and add Geocoding information 
                return [
                    h(IonItem, () => [
                        h(IonLabel, { position: 'stacked' }, () => props.label),
                        h(IonInput, {
                            debounce: 300,
                            value: currentInfo.value,
                            onIonChange: async (ev) => {
                                // dont perform request if the input value is changed
                                // by clicking a autocomplete suggestion
                                if (alreadyPerformedRequest) {
                                    alreadyPerformedRequest = false;
                                    return;
                                }
                                // check if the queryText at least contains elements
                                const queryText = ev.detail.value;
                                if (queryText.length < 2)
                                    return;
                                // perform geocoding Request and pass the result
                                // to the geoFeatureListRef
                                const geocodingResponse = await fetch(`https://geo1.living-apps.de/api?q=${encodeURIComponent(queryText)}&limit=5`);
                                const geocodingJSON = await geocodingResponse.json();
                                const geoFeatureList = geocodingJSON.features;
                                if (geoFeatureList instanceof Array) {
                                    geoFeatureListRef.value = geoFeatureList;
                                }
                                if (geocodingJSON.features[0]) {
                                    geoFeatureListRef.value = geoFeatureList;
                                }
                            }
                        }),
                        // toggle detailview
                        h(IonButton, {
                            slot: 'end',
                            fill: 'clear',
                            onClick: () => {
                                detailView.value = true;
                            }
                        }, () => [
                            h(IonIcon, { icon: buildOutline })
                        ]),
                    ]),
                    // coyright links
                    h(IonItemDivider, { style: 'font-size: 12px;' }, () => h(IonText, () => [
                        'Daten von ',
                        h('a', { href: 'https://www.openstreetmap.org/' }, 'OpenStreetMap'),
                        ' - Veröffentlicht unter ',
                        h('a', { href: 'https://opendatacommons.org/licenses/odbl/' }, 'ODbL'),
                    ])),
                    // Bild a Suggestionlist based on the geoFeatureList
                    geoFeatureListRef.value.length > 0
                        ? h(IonItemGroup, () => geoFeatureListRef.value.map((geoFeature) => h(IonItem, {
                            lines: 'full',
                            color: 'medium',
                            onClick() {
                                currentLatLng.value = geoFeature.geometry.coordinates;
                                currentInfo.value = formatAddress(geoFeature);
                                geoFeatureListRef.value = [];
                                alreadyPerformedRequest = true;
                                handleGeoInput({
                                    lat: currentLatLng.value[0],
                                    lng: currentLatLng.value[1],
                                    info: currentInfo.value,
                                }, props.identifier, props.recordRef);
                            }
                        }, () => h(IonLabel, () => formatAddress(geoFeature)))))
                        : undefined,
                ];
            }
        };
    }
});
