import { alertController, IonContent, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonFab, IonFabButton, IonFabList, IonText, onIonViewDidEnter, loadingController, } from '@ionic/vue';
import { add as addIcon, alertOutline, arrowBack, ellipsisVertical, settingsOutline, chatboxOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Record from '../components/Record';
import { createLocalRecord } from '../models/record';
import { getControlOrdersForApp } from '../services/db/controlorder';
import { getApp } from '../services/db/apps';
import { storeNewRecord, getRecordList, storeRecordList } from '../services/db/records';
import { mutateRecord, queryRecordList } from '../services/graphql';
import { filterControls } from '../utils/utils';
import { AppDoesNotExistError } from '../utils/errors';
import { removeRecordMutation, storeRecordMutation } from '@/services/db/recordmutations';
import env from '@/utils/env';
export default defineComponent({
    components: {
        IonContent,
        IonIcon,
        IonImg,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
        IonPage,
        IonFab,
        IonFabButton,
        IonFabList,
        IonText,
        Record,
    },
    props: {
        appId: String,
    },
    setup(props) {
        const router = useRouter();
        const app = ref();
        const controlOrders = ref();
        const records = ref();
        const recordPreviewControls = ref();
        const offset = ref(0);
        const renderIndex = ref(0);
        watch([app, controlOrders], () => {
            var _a;
            recordPreviewControls.value = app.value
                ? filterControls(app.value.controls, (_a = controlOrders.value) !== null && _a !== void 0 ? _a : [])
                : undefined;
        });
        async function handleUpdateRecordList() {
            var _a;
            const loading = await loadingController.create({
                message: 'Update Einträge',
            });
            try {
                await loading.present();
                const recList = await queryRecordList(props.appId);
                await storeRecordList((_a = props.appId) !== null && _a !== void 0 ? _a : '', recList);
                const newRecords = await getRecordList(props.appId);
                records.value.splice(0, records.value.length, ...newRecords);
                renderIndex.value++;
                offset.value = 0;
                await loading.dismiss();
            }
            catch (err) {
                console.error(err.message);
                await loading.dismiss();
            }
        }
        async function handleCreateRecord() {
            const newRecord = createLocalRecord(app.value);
            records.value.unshift(newRecord);
        }
        async function handleRemoveRecord(id) {
            const recordArrayIndex = records.value.findIndex(rec => rec.id === id);
            records.value.splice(recordArrayIndex, 1);
        }
        async function handleSendNewRecord(record) {
            try {
                record.id = `loc_${record.id}`;
                console.log(record);
                await storeNewRecord(record);
                await storeRecordMutation(record);
                await mutateRecord(record);
                await removeRecordMutation(record.id);
                await handleUpdateRecordList();
            }
            catch (err) {
                console.error(err);
            }
        }
        onIonViewDidEnter(async () => {
            const loading = await loadingController.create({
                message: 'Lade App',
            });
            await loading.present();
            try {
                if (props.appId) {
                    app.value = await getApp(props.appId);
                    controlOrders.value = await getControlOrdersForApp(props.appId);
                    records.value = await getRecordList(props.appId);
                    await loading.dismiss();
                    await handleUpdateRecordList();
                }
                else
                    throw new Error('appid is not set');
            }
            catch (error) {
                console.error(error);
                await loading.dismiss();
                if (error instanceof AppDoesNotExistError) {
                    const alert = await alertController.create({
                        message: 'App existiert nicht',
                        buttons: [
                            {
                                text: 'Gehe zurück',
                                handler: router.back,
                            }
                        ],
                    });
                    await alert.present();
                }
                else {
                    const alert = await alertController.create({
                        message: 'Ein unbekannter Fehler ist aufgetreten',
                        buttons: [
                            {
                                text: 'Gehe zurück',
                                handler: router.back,
                            }
                        ],
                    });
                    await alert.present();
                }
            }
        });
        async function handleInfiniteEvent(evt) {
            const infiteScroll = evt.target;
            console.log('triggers infinite');
            offset.value++;
            const newRecords = await getRecordList(props.appId, offset.value * 5);
            records.value = records.value.concat(newRecords);
            infiteScroll.complete();
        }
        return {
            addIcon,
            alertOutline,
            app,
            arrowBack,
            chatboxOutline,
            ellipsisVertical,
            env,
            handleCreateRecord,
            handleBack() {
                router.back();
            },
            handleSendNewRecord,
            handleInfiniteEvent,
            handleRemoveRecord,
            offset,
            records,
            recordPreviewControls,
            renderIndex,
            router,
            settingsOutline,
        };
    },
});
